import React, {
  createContext,
  useEffect,
  useRef,
  useContext,
  useState,
} from "react";
import socketIOClient from "socket.io-client";
import { socketUrl } from "../components/constant/BaseUrl";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [tokenLocal, setTokenLocal] = useState(
    localStorage.getItem("jwtToken")
  );

  useEffect(() => {
    connectSocket(tokenLocal);

    return () => {
      disconnectSocket();
    };
  }, [tokenLocal]);

  const connectSocket = (token) => {
    setTokenLocal(token);
    if (!socket.current && tokenLocal) {
      socket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: token || localStorage.getItem("jwtToken"),
        },
      });

      socket.current.on("connect", () => {
        setIsConnected(true);
        console.log("WebSocket connected");
      });

      socket.current.on("disconnect", () => {
        setIsConnected(false);
        console.log("WebSocket disconnected");
      });

      socket.current.on("error", (err) => {
        console.error("WebSocket error:", err);
      });
    }
  };

  const disconnectSocket = () => {
    if (socket.current) {
      socket.current.disconnect();
      socket.current = null;
      setIsConnected(false);
      console.log("Socket disconnected manually");
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket: socket.current,
        isConnected,
        disconnectSocket,
        connectSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
