import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWebSocket } from "../../websocket/WebSocketProvider";

const Navbar = () => {
  const { socket } = useWebSocket();
  const location = useLocation(); // To track the current URL
  const [activeTab, setActiveTab] = useState(location.pathname); // Initial active tab

  const handleSocketStop = (type, path) => {
    if (socket) {
      socket.emit("stopAcctData", { type });
    }
    setActiveTab(path); // Update the active tab when clicked
  };

  return (
    <div className="navBar  gap-4 d-flex justify-content-between align-items-center p-2">
      <div
        className={`crypto d-nav-link ${
          activeTab === "/dashboard-crypto" ? "active" : ""
        }`}
      >
        <Link
          onClick={() => handleSocketStop("crypto", "/dashboard-crypto")}
          to="/dashboard-crypto"
        >
          Crypto
        </Link>
      </div>
      <div
        className={`forex d-nav-link ${
          activeTab === "/dashboard-forex" ? "active" : ""
        }`}
      >
        <Link
          onClick={() => handleSocketStop("forex", "/dashboard-forex")}
          to="/dashboard-forex"
        >
          Forex
        </Link>
      </div>

      <div
        className={`equity d-nav-link ${
          activeTab === "/dashboard-equity" ? "active" : ""
        }`}
      >
        <Link
          onClick={() => handleSocketStop("equity", "/dashboard-equity")}
          to="/dashboard-equity"
        >
          Equity
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
