import { apiSlice } from "./api";
import { closeOrdersApi } from "../components/constant/Api";

export const closeOrdersRiskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getWidgetPermissions: builder.query({
    //   query: ({ userId, type }) => ({
    //     url: `${getWidgetPermissionsApi}/${userId}/${type}`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response, meta, arg) => {
    //     return response.status ? response?.data ?? [] : [];
    //   },
    //   invalidatesTags: (_) => ["closeOrdersRisk"],
    // }),
    closeOrders: builder.mutation({
      query: (post) => ({
        url: closeOrdersApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["closeOrdersRisk"],
    }),
  }),
});

export const { useCloseOrdersMutation } = closeOrdersRiskApi;
