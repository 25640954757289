import React from "react";
import Navbar from "./Navbar";

const Logo = ({ handleLogout }) => {
  return (
    <div className="d-flex justify-content-between align-items-center p-2">
      <div>
        <img src="/assets/img/trader.png" alt="trader" className="trader" />
      </div>

      <div className="d-flex justify-content-between align-items-center p-2">
        <Navbar />
        <button className="btn text-white" onClick={handleLogout}>
          <i className="bi bi-power"></i>
        </button>
      </div>
    </div>
  );
};

export default Logo;
