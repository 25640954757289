import React, { useEffect, useState } from "react";
import "./Accounts.css";
import Loader from "./Loader";
import AccountTable from "./AccountTable";
const Accounts = ({ data, onAccountClick, isLoading, handleCloseOrders }) => {
  const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
    setPreviousData(data);
  }, [data]);

  return (
    <div className={isLoading ? "loader-center" : "card-body min-height "}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="scroll_table scroll_table2">
          <AccountTable
            data={data}
            onAccountClick={onAccountClick}
            previousData={previousData}
            onCloseOrders={handleCloseOrders}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
