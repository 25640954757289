import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import DataCell from "./DataCell";

const PositionsSummeryTable = ({ data, previousData }) => {
  const createOneNo = (no1, no2) => {
    return Number(no1 + no2).toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "currency_pair_symbol",
        header: "Sym",
        size: 10,
      },
      {
        accessorKey: "user_name",
        header: "Acct",
        size: 10,
        Cell: ({ row }) => <div>{row.original.user_name}</div>,
      },
      {
        accessorKey: "total_qty_bought",
        header: "QtyBot",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.total_qty_bought}
              previousValue={prevItem.total_qty_bought || 0}
            />
          );
        },
      },
      {
        accessorKey: "total_qty_sold",
        header: "QtySld",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.total_qty_sold}
              previousValue={prevItem.total_qty_sold || 0}
            />
          );
        },
      },

      {
        accessorKey: "net_quantity_open",
        header: "Pos",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.net_quantity_open}
              previousValue={prevItem.net_quantity_open || 0}
            />
          );
        },
      },
      {
        accessorKey: "realized_pl",
        header: "$Real",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.realized_pl}
              previousValue={prevItem.realized_pl}
            />
          );
        },
      },
      {
        accessorKey: "unrealized_pl",
        header: "$Unreal",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.unrealized_pl}
              previousValue={prevItem.unrealized_pl || 0}
            />
          );
        },
      },
      {
        accessorKey: "pos_avg_price_buy",
        header: "$PosAvgPrcBot",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.pos_avg_price_buy}
              previousValue={prevItem.pos_avg_price_buy}
            />
          );
        },
      },
      {
        accessorKey: "pos_avg_price_sell",
        header: "$PosAvgPrcSld",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.pos_avg_price_sell}
              previousValue={prevItem.pos_avg_price_sell}
            />
          );
        },
      },
      // {
      //   accessorKey: "maxLoss",
      //   header: "Max Loss",
      //   size: 10,
      //   Cell: ({ row }) => {
      //     const prevItem = previousData[row.index] || {};
      //     return (
      //       <DataCell
      //         value={row.original.maxLoss}
      //         previousValue={prevItem.maxLoss || 0}
      //       />
      //     );
      //   },
      // },
      // {
      //   accessorKey: "total_pl",
      //   header: "$Total P/L",
      //   size: 10,
      //   Cell: ({ row }) => {
      //     const prevItem = previousData[row.index] || {};
      //     return (
      //       <DataCell
      //         value={row.original.total_pl}
      //         previousValue={prevItem.total_pl || 0}
      //       />
      //     );
      //   },
      // },
      // {
      //   accessorKey: "net_quantity_closed",
      //   header: "Close Qty",
      //   size: 10,
      //   Cell: ({ row }) => {
      //     const prevItem = previousData[row.index] || {};
      //     return (
      //       <DataCell
      //         value={row.original.net_quantity_closed}
      //         previousValue={prevItem.net_quantity_closed}
      //       />
      //     );
      //   },
      // },
    ],
    [previousData]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    columnFilterDisplayMode: "popover",
    columnDefType: "display",
    enableClickToCopy: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnOrdering: false,
    enableEditing: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    enableHiding: false,
    enableResizing: false,
    enableStickyHeader: false,
    // enableStickyFooter: true,
    enableSorting: true,
    enablePagination: false,
    enableKeyboardShortcuts: false,
    enableColumnFilters: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    initialState: { density: "compact" },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#090c10", //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",

        caption: {
          captionSide: "top",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        fontSize: "12px",
        // fontStyle: "italic",
        // fontWeight: "normal",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        fontSize: "12px",
      },
    },
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: () => onAccountClick(row.original.user_id),

    //   // setRowSelection((prev) => ({
    //   //   ...prev,
    //   //   [row.id]: !prev[row.id],
    //   // })),
    //   //   selected: rowSelection[row.id],
    //   sx: {
    //     cursor: "pointer",
    //   },
    // }),
  });

  return <MaterialReactTable table={table} />;
};

export default PositionsSummeryTable;
