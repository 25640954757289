import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const DataCell = ({ value, previousValue }) => {
  const [showChange, setShowChange] = useState(false);
  const [color, setColor] = useState(""); // Track the color state
  const [isDefault, setIsDefault] = useState(true); // Track if we should revert back to default color

  useEffect(() => {
    if (previousValue !== undefined && previousValue !== value) {
      // Set color based on whether the value increased or decreased
      setColor(value > previousValue ? "green" : "red");
      setShowChange(true);
      setIsDefault(false); // Disable the default color

      // Remove the highlight after 100ms and revert back to default color
      const timeout = setTimeout(() => {
        setShowChange(false);
        setColor(""); // Revert to default color
        setIsDefault(true); // Re-enable default color
      }, 100); // The duration you want the highlight to last

      return () => clearTimeout(timeout);
    }
  }, [value, previousValue]);

  return (
    <TransitionGroup component={null}>
      <CSSTransition in={showChange} timeout={500} classNames="data-change">
        <td className={isDefault ? "" : color}>{value}</td>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DataCell;
