"use client";
import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-tabs/style/react-tabs.css";
import "react-phone-input-2/lib/style.css";
import "./components/utils/i18n";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./components/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./components/widgets/Dashboard";
import Button from "./components/widgets/Button";
import DashboardForex from "./components/widgets/DashboardForex";
import DashboardEquity from "./components/widgets/DashboardEquity";
import { WebSocketProvider } from "./websocket/WebSocketProvider";
import DashboardAll from "./components/widgets/DashboardAll";

// import "bootstrap/dist/css/bootstrap.min.css";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<Login />} />
      <Route path="" element={<ProtectedRoute />}>
        <Route path="/dashboard-crypto" element={<Dashboard />} />
        <Route
          index={true}
          path="/dashboard-forex"
          element={<DashboardForex />}
        />
        <Route
          // index={true}
          path="/dashboard-equity"
          element={<DashboardEquity />}
        />
        <Route
          // index={true}
          path="/dashboard-all"
          element={<DashboardAll />}
        />
      </Route>
      <Route path="*" element={<Dashboard />} />
    </Route>
  )
);
const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center">Loading...</div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <WebSocketProvider>
            <RouterProvider router={router} />
          </WebSocketProvider>
        </ErrorBoundary>
      </React.Suspense>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
