import React, { useEffect, useState } from "react";
import DataCell from "./DataCell";
import Loader from "./Loader";
import PositionsSummeryTable from "./PositionSummerTable";

const PositionsSummary = ({ data, isLoading }) => {
  const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
    setPreviousData(data);
  }, [data]);

  return (
    <div className="card h100vh">
      <h6 className="mb-0 h6_hadding">
        <strong>Position Summary</strong>
      </h6>

      <div className={isLoading ? " loader-center" : "card-body"}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <PositionsSummeryTable data={data} previousData={previousData} />
            {/* <PositionsSummary data={data} previousData={previousData} /> */}
            {/* <div className="scroll_table">
              <table className="table table-dark table-hover table-striped text-end">
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Acct</th>
                    <th>Qty Bot</th>
                    <th>Qty Sld</th>
                    <th>Pos</th>
                    <th>$Unreal</th>
                    <th>$Real</th>

                    <th>$PosAvgPrc</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, rowIndex) => {
                      const prevItem = previousData[rowIndex] || {};

                      return (
                        <tr key={item.user_name + item.currency_pair_symbol}>
                          <td>{item.currency_pair_symbol}</td>
                          <td>{item.user_name}</td>
                          <DataCell
                            value={item.total_qty_bought}
                            previousValue={prevItem.total_qty_bought}
                          />
                          <DataCell
                            value={item.total_qty_sold}
                            previousValue={prevItem.total_qty_sold}
                          />
                          <DataCell
                            value={item.net_quantity_open}
                            previousValue={prevItem.net_quantity_open}
                          />
                          <DataCell
                            value={item.unrealized_pl}
                            previousValue={prevItem.unrealized_pl}
                          />
                          <DataCell
                            value={item.realized_pl}
                            previousValue={prevItem.realized_pl}
                          />
                          <DataCell
                            value={item.pos_avg_price}
                            previousValue={prevItem.pos_avg_price}
                          />
                        </tr>
                      );
                    })
                  ) : (
                    <div>Nothing to show</div>
                  )}
                </tbody>
              </table>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default PositionsSummary;
