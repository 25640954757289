import { baseUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login";
export const changePasswordApi = baseUrl + "/change-password";

// ===================== users =========================
export const getUsersApi = baseUrl + "/users";
export const getUserPermissionsApi = baseUrl + "/user-permissions";
export const userStatus = baseUrl + "/user-status";
export const userTwoFA = baseUrl + "/user-two-fa";
export const userDelete = baseUrl + "/user-delete";
export const addBalanceApi = baseUrl + "/add-balance";
export const changePasswordAdminApi = baseUrl + "/change-password-admin";
export const assetPermissions = baseUrl + "/asset-permissions";
export const getAssetPairs = baseUrl + "/get-asset-pairs";

// =============== dashboard ============
export const getDashboard = baseUrl + "/dashboard";

// ==================== brokers =========================
export const getBrokersApi = baseUrl + "/brokers";
export const getBrokersOfSuperAdminApi = baseUrl + "/brokersOfSuperAdmin";
export const postBrokerApi = baseUrl + "/add-broker";
export const updateBrokerApi = baseUrl + "/update-broker";
export const deleteBrokerApi = baseUrl + "/delete-broker";
export const assignCoinsIBApi = baseUrl + "/assign-coins-ib";
export const getIBPermissionsApi = baseUrl + "/ib-permissions";
export const getBrokerCredentialsApi = baseUrl + "/broker-credentials";
export const postBrokerCredentialsApi = baseUrl + "/broker-credentials";
export const getPermissions = baseUrl + "/get-permissions";

// =====================widget permissions ====================
export const closeOrdersApi = baseUrl + "/close-position-risk";
