import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logIn } from "../services/Login";
import { LoginValid } from "../validations/LoginValid";
import { setCredentials } from "../../redux/authSlice";
import toast from "react-hot-toast";
import { useGetUserPermissionsMutation } from "../../redux/usersApi";
import { useWebSocket } from "../../websocket/WebSocketProvider";

const Login = () => {
  const { connectSocket } = useWebSocket();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [getPermissions] = useGetUserPermissionsMutation();
  const navigate = useNavigate();
  const [loginField, setLoginField] = useState({ email: "", password: "" });
  const [loginFieldErr, setLoginFieldErr] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/dashboard-forex");
    }
  }, [navigate, user, isAuthenticated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginField({ ...loginField, [name]: value });
    let checkLogin = LoginValid(name, value);
    setLoginFieldErr({ ...loginFieldErr, [name]: checkLogin });
  };

  const onLogin = async (event) => {
    event.preventDefault();
    for (let key in loginField) {
      const checkLogin = LoginValid(key, loginField[key]);
      setLoginFieldErr({ ...loginFieldErr, [key]: checkLogin });
      if (checkLogin !== "") return false;
    }
    const LoginData = {
      email: loginField.email,
      password: loginField.password,
    };
    const result = await logIn(LoginData);

    if (result.status) {
      const { token } = result;
      localStorage.setItem("jwtToken", token);
      const userPermissions = await getPermissions().unwrap();
      if (userPermissions) {
        dispatch(setCredentials(userPermissions));
        toast.success(result.message);
        navigate("/dashboard-forex");
        connectSocket(token);
        // setTimeout(() => navigate("/dashboard", { replace: true }), 2000);
      }
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };

  return (
    <section className="d-flex align-items-center min-vh-100 py-3 py-md-0 main_bg login_bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 m-auto">
            <div className="bg2 login-card">
              <div className="row no-gutters">
                <div className="col-md-6 position-relative">
                  <img
                    src="/assets/img/login.jpg"
                    alt="login"
                    className="login-card-img"
                  />
                </div>
                <div className="col-md-6">
                  <div className="card-body">
                    <div className="brand-wrapper mb-4">
                      <img
                        src="/assets/img/logo.png"
                        alt="logo"
                        className="logo"
                      />
                    </div>
                    <p className="login-card-description">
                      Sign in to your account
                    </p>
                    <form action="#!">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email address"
                          onChange={handleChange}
                          value={loginField.email}
                        />
                        <span className="text-danger">
                          {loginFieldErr.email}
                        </span>
                      </div>
                      <div className="form-group ">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="***********"
                          onChange={handleChange}
                          value={loginField.password}
                        />
                        <span className="text-danger">
                          {loginFieldErr.password}
                        </span>
                      </div>
                      {/* <div className="form-group text-end">
                        <a href="#" className="color1">
                          Forgot Password
                        </a>
                      </div> */}
                      <button
                        type="submit"
                        className="btn btn1 btn-block login-btn mb-4 w-100 p-3"
                        onClick={onLogin}
                      >
                        Log In
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
